import React, {Component} from 'react';
import lib from 'library'

const styles ={
  footerStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
    //backgroundColor: 'rgba(57,169,71,1)',
    //fontFamily: 'HelveticaNeueLTStd-Lt',
  },
  container:{
    width: 1024,
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    color: 'rgba(53,107,179,1)',
    fontSize: 30,
    fontFamily: 'HelveticaNeueLTStd-Bd',
  },
  content: {
    color: 'black',
    fontSize: 22,
    fontFamily: 'HelveticaNeueLTStd-ThEx',
    padding: 15,
  }
}


class SectionProducts extends Component {


  render(){

    const lang = lib.lc.currentLang(this.props.pathname)

    return(
      <div style={styles.footerStyle}>
        <div style={styles.container}>

          <div style={{
              display: 'flex',
              flex: 1,
              minWidth: 350,
            }}>
            <p style={styles.content}>{lc[lang].info1}
              <br></br>
              <br></br>
              {lc[lang].info2}
              <br></br>
              <br></br>
              {lc[lang].info3}</p>
          </div>




        </div>

      </div>
    )
  }
}

const lc = {
  en:{
    info1: 'Luggage: Hard Case, Soft Case, ABS Case',
    info2: 'Backpacks: Business Backpacks, Lifestyle Backpacks',
    info3: 'Accessories: Luggage Straps, Eye Mask, Travel Pillow, Suitcase Cover, Umbrella',
  },
  zh:{
    info1: '行李箱： 硬箱，軟箱，ABS箱',
    info2: '背包：商業背包，生活背包',
    info3: '配件：行李箱帶，眼罩，旅行枕頭，行李箱套，雨傘',
  }
}

export default SectionProducts
