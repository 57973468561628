import React, {Component} from 'react';
import Helmet from 'react-helmet'
import lib from 'library'

import TitleBar from 'components/TitleBar.js'
import SectionProducts from 'components/SectionProducts.js'
import SectionGallery from 'components/SectionGallery.js'
import Layout from "components/layout"

export default class App extends Component {
  render(){

    const lang = lib.lc.currentLang(this.props.location.pathname)

    return(

      <Layout pathname={this.props.location.pathname}>

          <div >
            <Helmet
              title={lc[lang].pagename}
              meta={[
                { name: 'description', content: '冠信控股有限公司成立於2014年，主要業務是代工生產箱包、背包和旅行配件。' },
                { name: 'keywords', content: '冠信控股有限公司, 冠信, 行李箱, 背包, 旅行配件' },
              ]}
            />

          <div style={{height: 50}} />
            <TitleBar title={lc[lang].title1} />
            <SectionProducts pathname={this.props.location.pathname} />
            <SectionGallery array={arrayProducts} />
            <div style={{height: 100}} />
            <TitleBar title={lc[lang].title2} />
            <SectionGallery array={arrayFactory} />
            <div style={{height: 100}} />

          </div>
</Layout>
    )
  }
}


const lc = {
  en:{
    pagename: 'Products - Kun Shung Holdings Limited',
    title1: 'Major Products',
    title2: 'Factory',
  },
  zh:{
    pagename: '產品 - 冠信控股有限公司',
    title1: '主要產品',
    title2: '生產廠房',
  }
}

const arrayProducts =[
  require('assets/products/Gear.jpg'),
  require('assets/products/GraniteGearBags.jpg'),
  require('assets/products/TravelPlus.jpg'),
  require('assets/products/TravelPlusBags.jpg'),
  require('assets/products/Wenger.jpg'),
  require('assets/products/Wenger2.jpg'),
]

const arrayFactory =[
  require('assets/factory/Factory1.jpg'),
  require('assets/factory/Factory2.jpg'),
  require('assets/factory/IMG_7687345345.jpg'),
  require('assets/factory/IMG_7691345345.jpg'),
  require('assets/factory/IMG_7696345345.jpg'),
  require('assets/factory/IMG_7704345345.jpg'),
  require('assets/factory/IMG_7706345345.jpg'),

]
